<template>
  <div class="crowd">
    <el-container>
      <el-aside width="160px">
        <el-menu
            router
            class="el-menu-vertical-demo"
            @open="handleOpen"
            @close="handleClose">
          <el-menu-item v-if="isManager" :index="`/crowd/${$route.params.id}/member`">
            <i class="el-icon-setting"></i>
            <span slot="title">成员管理</span>
          </el-menu-item>
          <el-menu-item v-else  :index="`/crowd/${$route.params.id}/member-info`">
            <i class="el-icon-setting"></i>
            <span slot="title">成员展示</span>
          </el-menu-item>

          <el-menu-item :index="`/crowd/${$route.params.id}/homework`">
            <i class="el-icon-menu"></i>
            <span slot="title">课堂作业</span>
          </el-menu-item>
          <el-menu-item :index="`/crowd/${$route.params.id}/information`">
            <i class="el-icon-document"></i>
            <span slot="title">教学资料</span>
          </el-menu-item>
          <el-menu-item :index="`/crowd/${$route.params.id}/signIn`">
            <i class="el-icon-setting"></i>
            <span slot="title">在线签到</span>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-main class="crowd-main">
        <router-view :isManager="isManager"></router-view>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import {selectCrowdById} from "@/api/crowd";

export default {
  name: "index",
  data() {
    return {
      isManager: false
    }
  },
  mounted() {
    selectCrowdById(this.$route.params.id).then(result => {
      let crowd = result.data.crowd;
      if (crowd.uid === this.$store.state.user.id) {
        console.log("为群主")
        this.isManager = true;
      }
    }).catch(error => {
      this.$message(error.message);
    })
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    }
  }
}
</script>

<style scoped>
.el-menu-vertical-demo {
  text-align: left;
  min-height: calc(100vh - 120px);
  border: 1px solid #e6e6e6;
  /*margin-right: 10px;*/
}

.crowd {
  min-height: calc(100vh - 120px);
  width: 100%;
  /*margin-top: 1px;*/
}

.crowd-main {
  background-color: #fff;
}
</style>